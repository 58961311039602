
import { flags } from "@/feature-flags";
import { Vue } from "vue-class-component";

export default class Breadcrumb extends Vue {
  get getCurrentRoute() {
    return this.$route;
  }
  get id() {
    return this.$route.meta.customPath
      ? this.$route.meta.title
      : this.$route.params.id;
  }
  get params() {
    return this.$route.query;
  }
  goHome() {
    this.$router.push(flags.flag_default_path_genesis.getValue());
  }
  goPath(path: any) {
    this.$router.push(path);
  }
  goDetail(path: any, id: string) {
    const detail = id && path.replace(":id", id);
    this.$router.push(detail);
  }
}
